<template>
  <div class="app-container v" v-loading.fullscreen.lock="closing">
    <div class="head-container">
      <quick-select v-model="query.provinceCode" clearable class="filter-item" url="api/regionalDict/province" value-field="code" placeholder="省份" filterable style="width:100px;" @change="query.cityCode = null; toQuery();" />
      <quick-select v-model="query.cityCode" clearable class="filter-item" :url="query.provinceCode ? `api/regionalDict/parent/${query.provinceCode}` : ''" value-field="code" placeholder="城市" filterable :disabled="!query.provinceCode" style="width: 100px;" @change="toQuery" />
      <quick-select v-model="query.buyerId" url="api/distributor" placeholder="经销商" clearable filterable @change="toQuery" value-field="enterpriseId" class="filter-item" style="width: 180px;" />
      <quick-select v-model="query.buyerErpId" url="api/distributorErps/list" :params="{ entId: query.buyerId }" placeholder="下单账户" value-field="erpId" display-field="name" @change="toQuery" class="filter-item" style="width: 180px;" clearable v-if="query.buyerId" />
      <el-date-picker v-model="query.dateRange" type="daterange" class="filter-item" style="width: 220px;" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="searchDateRangeOptions" value-format="timestamp" :default-time="['00:00:00', '23:59:59']" @change="toQuery" />
      <template v-if="checkPermission(['WHOLESALE_ALL', 'WHOLESALE_PAY'])">
        <el-select v-model="query.payType" placeholder="支付方式" style="width: 120px;" class="filter-item" clearable @change="query.payStatus = null; toQuery();">
          <el-option v-for="(v, i) in payTypes" :key="i" :label="v" :value="i" />
        </el-select>
        <el-select v-model="query.payStatus" placeholder="付款状态" style="width:120px" class="filter-item" clearable @change="toQuery" :disabled="query.payType !== 0 && query.payType !== 1">
          <template v-for="(item, i) in payStatus[query.payType]">
            <el-option v-if="item.hidden !== true" :key="i" :label="item.label" :value="i"></el-option>
          </template>
        </el-select>
      </template>
      <el-select v-model="query.statusList" placeholder="订单状态" style="width:150px" class="filter-item" clearable @change="toQuery" multiple collapse-tags>
        <el-option v-for="item in status" :key="item.id" :label="item.label" :value="item.id"></el-option>
      </el-select>
      <el-select v-model="query.erpSyn" filterable clearable placeholder="上报状态" class="filter-item" style="width: 120px;" @change="toQuery" v-if="reportable && $entType.get() === 'kingkoil'">
        <el-option label="已上报" :value="true" />
        <el-option label="未上报" :value="false" />
      </el-select>
      <el-select v-model="query.isMiniProgram" filterable clearable placeholder="操作来源" class="filter-item" style="width: 120px;" @change="toQuery">
        <el-option label="小程序" :value="true" />
        <el-option label="电脑端" :value="false" />
      </el-select>
      <el-input v-model="query.formCode" class="filter-item" :maxlength="20" placeholder="销售单号或ERP单号" @keypress.enter.native="toQuery" style="width: 160px;" />
      <el-input v-model="query.goodsCodeOrName" class="filter-item" :maxlength="20" placeholder="商品ERP编码或名称" @keypress.enter.native="toQuery" style="width: 160px;" />
      <el-button size="mini" class="filter-item" type="success" icon="el-icon-search" @click="toQuery">搜索</el-button>
      <el-button type="primary" size="mini" class="filter-item" @click="importOrder" icon="el-icon-upload2" v-if="checkPermission(['WHOLESALE_ALL', 'WHOLESALE_IMPORT'])">导入</el-button>
      <el-button class="filter-item" size="mini" type="info" icon="el-icon-download" :loading="downloadLoading" @click="toDownload">导出</el-button>
      <el-button class="filter-item" size="mini" type="info" icon="el-icon-refresh-left" @click="toQuery">刷新</el-button>
    </div>

    <el-card shadow="never" class="no-flex" v-if="statementType === 'serta'">
      <div class="h">
        <div class="money-count-item">
          <div class="fc-g">订单金额</div>
          <counter class="num" :end-val="totalOrderAmount / 100" :decimals="2" :duration="1000" />
        </div>
        <div class="money-count-item">
          <div class="fc-g">返利抵扣（预估）</div>
          <counter class="num" :class="totalRebateAmount > 0 ? 'fc-wa' : 'fc-g'" :end-val="totalRebateAmount / 100" :decimals="2" :duration="1000" />
        </div>
        <div class="money-count-item">
          <div class="fc-g">应付金额</div>
          <counter class="num" :end-val="totalPayAmount / 100" :decimals="2" :duration="1000" />
        </div>
        <div class="money-count-item">
          <div class="fc-g">账户余额</div>
          <div class="fc-g" style="line-height: 30px;" v-if="balance.loading">加载中…</div>
          <counter class="num" :end-val="balance.amount / 100" :decimals="2" :duration="1000" v-else />
        </div>
        <div class="money-count-item">
          <div class="fc-g">当前差额</div>
          <counter class="num" :class="balanceDiffAmount > 0 ? 'fc-e' : 'fc-g'" :end-val="balanceDiffAmount / 100" :decimals="2" :duration="1000" />
        </div>
      </div>
    </el-card>

    <el-table ref="table" v-loading="loading" row-key="id" :data="data" :default-sort="{ prop: 'purchaseTime', order: 'descending' }" @sort-change="handleSort" @selection-change="handleSelectionChange" @select-all="handleSelectAll" height="200">
      <el-table-column key="0" type="selection" :selectable="checkSelectable" v-if="statementType === 'serta'" />
      <el-table-column key="1" prop="formCode" label="销售单号" width="120" sortable="custom">
        <template slot-scope="scope">
          <a class="primary" href="javascript:void(0)" @click="edit(scope.row)">{{ scope.row.formCode }}</a>
        </template>
      </el-table-column>
      <el-table-column key="2" prop="erpFormCode" label="ERP单号" width="110" :formatter="v => { return v.erpSyn ? v.erpFormCode : '' }" sortable="custom" />
      <el-table-column key="3" prop="buyerErpId" label="经销商ERP编码" width="150" sortable="custom" />
      <el-table-column key="4" prop="buyerName" label="经销商名称" min-width="200" sortable="custom" show-overflow-tooltip />
      <el-table-column key="5" prop="totalMoney" label="总金额" width="120" align="right" sortable="custom">
        <template slot-scope="scope">{{ $price(scope.row.realTotalMoney || 0)}}</template>
      </el-table-column>
      <el-table-column key="6" label="支付方式" width="80" :formatter="r => { return payTypes[r.payType]; }" />

      <el-table-column key="7" prop="purchaseTime" label="下单时间" width="150" :formatter="r => { return new Date(r.purchaseTime).format(); }" sortable="custom" />
      <el-table-column key="8" label="收货地址" min-width="240" show-overflow-tooltip>
        <template slot-scope="scope">{{ scope.row.recProvince }}{{ scope.row.recCity }}{{ scope.row.recDistrict }}{{ scope.row.recAddress }}</template>
      </el-table-column>
      <el-table-column key="9" prop="info" label="摘要" min-width="160" show-overflow-tooltip />
      <el-table-column key="10" label="操作来源" prop="isMiniProgram" width="100" sortable="custom">
        <template slot-scope="scope">{{ scope.row.isMiniProgram ? "小程序" : "电脑端" }}</template>
      </el-table-column>
      <el-table-column key="11" label="付款情况" align="center" width="130" fixed="right" v-if="checkPermission(['WHOLESALE_ALL', 'WHOLESALE_PAY'])">
        <template slot-scope="scope">
          <el-tag :type="payStatus[scope.row.payType][scope.row.payStatus].type" :key="scope.row.id">{{ payStatus[scope.row.payType][scope.row.payStatus].label }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column key="11" label="状态" align="center" width="130" fixed="right">
        <template slot-scope="scope">
          <el-tag :type="status[scope.row.status].type">{{ status[scope.row.status].label }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column key="report" label="上报状态" align="center" width="100" fixed="right" v-if="reportable && $entType.get() === 'kingkoil'">
        <template slot-scope="scope">
          <el-tag :type="scope.row.erpSyn ? 'success' : 'info'">{{ scope.row.erpSyn ? "已上报" : "未上报" }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column key="cmd" width="240" fixed="right">
        <div class="row-commands" slot-scope="scope">
          <el-button size="mini" v-permission="['WHOLESALE_ALL', 'WHOLESALE_PAY']" type="text" @click="pay(scope.row)" v-if="checkPayable(scope.row)">付款情况</el-button>
          <el-button size="mini" type="text" @click="edit(scope.row)" v-if="checkPermission(['WHOLESALE_ALL', 'WHOLESALE_EDIT']) && scope.row.status === 1">编辑</el-button>
          <el-button size="mini" type="text" @click="edit(scope.row)" v-else>查看</el-button>
          <el-button size="mini" v-permission="['WHOLESALE_ALL', 'WHOLESALE_AUDIT']" type="text" @click="audit(scope.row)" v-if="scope.row.status === 1">审批</el-button>
          <el-button size="mini" v-permission="['WHOLESALE_ALL', 'WHOLESALE_AUDIT']" type="text" @click="report(scope.row)" v-if="scope.row.erpSyn === false && scope.row.payType === 0 && scope.row.payStatus === 5 && scope.row.status === 2 && $entType.get() === 'kingkoil'">上报</el-button>
          <el-button size="mini" v-permission="['WHOLESALE_ALL', 'WHOLESALE_CANCEL']" type="text" @click="cancel(scope.row)" class="danger" v-if="scope.row.status === 1">取消</el-button>
          <el-button size="mini" v-permission="['WHOLESALE_ALL', 'WHOLESALE_CLOSE']" type="text" @click="close(scope.row)" class="danger" v-if="checkClosable(scope.row)">关闭</el-button>
          <el-button size="mini" type="text" @click="print(scope.row.id)">打印</el-button>
        </div>
      </el-table-column>
    </el-table>
    <!--分页组件-->
    <el-pagination :total="total" :current-page="page + 1" style="margin-top: 8px;" layout="total, prev, pager, next, sizes" @size-change="sizeChange" @current-change="pageChange" />

    <e-form ref="form" />
    <pays receipt ref="pays" />
    <order-import ref="importForm" />
  </div>
</template>

<script>
import { getU9ByDistributor, getStatementType } from "@/api/capitalPool";
import checkPermission from "@/utils/permission";
import { close as closeOrder } from "@/api/distributorOrder";
import initData from "@/mixins/initData";
import eForm from "./form";
import pays from "../../purchase/order/pays";
import { download } from "@/api/data";
import { downloadFile } from "@/utils/index";
import request from "@/utils/request";
import orderImport from "./import";
import Counter from "vue-count-to";

export default {
  components: { eForm, pays, orderImport, Counter },
  mixins: [initData],
  data() {
    return {
      downloadLoading: false,
      delLoading: false,
      sort: "purchaseTime,desc",
      closing: false,
      reportable: false,
      statementType: null,
      selections: [],
      balance: {
        loading: false,
        distributorId: null,
        amount: 0,
      },
      searchDateRangeOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      payTypes: ["线上支付", "线下支付"],
      payStatus: [
        [
          {
            type: "info",
            label: "待付款",
          },
          {
            type: "info",
            label: "待付款",
            hidden: true,
          },
          {
            type: "warning",
            label: "部分付款成功",
          },
          {
            type: "info",
            label: "待付款",
            hidden: true,
          },
          {
            type: "danger",
            label: "付款失败",
          },
          {
            type: "primary",
            label: "付款成功",
          },
        ],
        [
          {
            type: "info",
            label: "待付款",
          },
          {
            type: "warning",
            label: "部分已付款",
          },
          {
            type: "warning",
            label: "部分付款已确认",
          },
          {
            type: "success",
            label: "已付款",
          },
          {
            type: "danger",
            label: "付款失败",
          },
          {
            type: "primary",
            label: "已完成",
          },
        ],
      ],
      status: [
        {
          type: "info",
          label: "编辑中",
          id: 0,
        },
        {
          type: "warning",
          label: "待审批",
          id: 1,
        },
        {
          type: "success",
          label: "已确认",
          id: 2,
        },
        {
          type: "danger",
          label: "已拒绝",
          id: 3,
        },
        {
          type: "warning",
          label: "待发货",
          id: 4,
        },
        {
          type: "success",
          label: "部分已发货",
          id: 5,
        },
        {
          type: "success",
          label: "已发货",
          id: 6,
        },
        {
          type: "success",
          label: "部分已收货",
          id: 7,
        },
        {
          type: "success",
          label: "已完成",
          id: 8,
        },
        {
          type: "info",
          label: "已关闭",
          id: 9,
        },
        {
          type: "info",
          label: "已取消",
          id: 10,
        },
        {
          type: "info",
          label: "部分已关闭",
          id: 11,
        },
      ],
      query: {
        provinceCode: null,
        cityCode: null,
        buyerId: null,
        dateRange: [
          new Date().getTime() - 3600 * 1000 * 24 * 30,
          new Date().getTime(),
        ],
        payStatus: null,
        status: null,
        formCode: null,
        goodsCodeOrName: null,
      },
    };
  },
  computed: {
    totalOrderAmount() {
      let amount = 0;
      if (this.selections && this.selections.length) {
        this.selections.forEach((o) => {
          amount += o.realTotalMoney || 0;
        });
      }
      return amount;
    },
    totalRebateAmount() {
      let amount = 0;
      if (this.selections && this.selections.length) {
        this.selections.forEach((o) => {
          amount += o.rebateAmount || 0;
        });
      }
      return amount;
    },
    totalPayAmount() {
      let amount = 0;
      if (this.selections && this.selections.length) {
        this.selections.forEach((o) => {
          amount += (o.realTotalMoney || 0) - (o.rebateAmount || 0);
        });
      }
      return amount;
    },
    balanceDiffAmount() {
      if (this.balance.distributorId && !this.balance.loading) {
        if (this.totalPayAmount > this.balance.amount)
          return Math.abs(this.totalPayAmount - this.balance.amount);
      }
      return 0;
    },
  },
  created() {
    this.checkReportable();
  },
  methods: {
    checkPermission,
    checkReportable() {
      request({
        url: "api/orderForm/onlineOrderConfirm",
        method: "get",
      })
        .then((res) => {
          this.reportable = String(res) === "true";
        })
        .finally(() => {
          this.$nextTick(this.init);
        });
    },
    checkSelectable(row) {
      if (row.status === 1) {
        if (this.selections && this.selections.length) {
          return row.buyerId === this.selections[0].buyerId;
        }
        return true;
      }
      return false;
    },
    beforeInit() {
      this.$refs.table && this.$refs.table.clearSelection();
      this.url = "api/orderForm";
      let query = JSON.parse(JSON.stringify(this.query));
      if (query.dateRange && query.dateRange.length === 2) {
        query.beginTime = query.dateRange[0];
        query.endTime = query.dateRange[1];
      }
      delete query.dateRange;
      this.params = Object.assign({ sort: this.sort }, query);
      return true;
    },
    handleSort(sort) {
      if (sort.order == null) this.sort = "";
      else
        this.sort =
          sort.prop + "," + (sort.order === "ascending" ? "asc" : "desc");
      this.toQuery();
    },
    subDelete(id) {
      this.delLoading = true;
      del(id)
        .then((res) => {
          this.delLoading = false;
          this.$refs[id].doClose();
          this.dleChangePage();
          this.init();
          this.$notify({
            title: "删除成功",
            type: "success",
            duration: 2500,
          });
        })
        .catch((err) => {
          this.delLoading = false;
          this.$refs[id].doClose();
        });
    },
    edit(data) {
      this.$refs.form && this.$refs.form.resetForm(data.id);
    },
    audit(data) {
      this.$refs.form && this.$refs.form.resetForm(data.id, true);
    },
    report(data) {
      this.$refs.form && this.$refs.form.resetForm(data.id, true, true);
    },
    print(id) {
      this.$print("wholesale", id);
    },
    checkClosable(data) {
      return data && [2, 4, 5, 6, 7, 11].indexOf(data.status) >= 0;
    },
    cancel(data) {
      this.$confirm(
        "您确定要取消订单【" + data.formCode + "】吗？",
        "操作确认",
        { type: "warning" }
      ).then((_) => {
        closeOrder(data.id).then((_) => {
          this.$notify({
            title: "取消销售单成功",
            type: "success",
            duration: 2500,
          });
          data.status = 10;
        });
      });
    },
    close(data) {
      this.$confirm(
        "您确定要关闭订单【" + data.formCode + "】吗？",
        "操作确认",
        { type: "warning" }
      ).then((_) => {
        this.closing = true;
        closeOrder(data.id)
          .then((_) => {
            this.$notify({
              title: "关闭销售单成功",
              type: "success",
              duration: 2500,
            });
            data.status = 9;
          })
          .finally((_) => {
            this.closing = false;
          });
      });
    },
    checkPayable(row) {
      return row && [0, 1, 3].indexOf(row.status) < 0;
    },
    pay(data) {
      if (data) {
        this.$refs.pays && this.$refs.pays.show(data);
      }
    },
    toDownload() {
      if (this.data && this.data.length === 0) {
        this.$message.info("当前无数据导出");
        return;
      }
      this.downloadLoading = true;
      download("api/orderForm/download", this.params)
        .then((result) => {
          downloadFile(result, "销售单数据", "xlsx");
          this.downloadLoading = false;
        })
        .catch((err) => {
          this.$message.error(err.message || "操作失败，请稍候再试。");
          this.downloadLoading = false;
        });
    },
    importOrder() {
      this.$refs.importForm && this.$refs.importForm.resetForm();
    },
    handleSelectionChange(selections) {
      this.selections = selections;
      if (selections && selections.length) {
        if (selections[0].buyerId !== this.balance.distributorId) {
          this.balance.distributorId = selections[0].buyerId;
          this.balance.loading = true;
          getU9ByDistributor(this.balance.distributorId)
            .then((res) => {
              this.balance.amount = (res && res.moneyBalance) || 0;
            })
            .finally(() => {
              this.balance.loading = false;
            });
        }
      } else {
        this.balance.distributorId = null;
        this.balance.amount = 0;
      }
    },
    handleSelectAll(selections) {
      let el = this.$refs.table;
      if (el && selections && selections.length) {
        let fs = selections[0];
        if (this.selections && this.selections.length) {
          fs = this.selections[0];
        }
        selections
          .filter((o) => o.buyerId !== fs.buyerId)
          .forEach((row) => {
            el.toggleRowSelection(row, false);
          });
      }
    },
  },
  mounted() {
    getStatementType().then((res) => {
      this.statementType = res;
    });
  },
};
</script>